export { }

window.global ||= window;

function detectBrowserSupported() {
    if (window.navigator.userAgent.match(/(MSIE|Trident|Edge)/)) {
        return false;
    }

    function hasDynamicImport() {
        try {
            // eslint-disable-next-line
            new Function('import("")');
            return true;
        } catch (err) {
            return false;
        }
    }

    return hasDynamicImport();
}

if (!detectBrowserSupported()) {
    document.body.style.fontFamily = '"Inter", sans-serif';
    document.body.style.fontSize = '1.125rem';
    document.body.style.fontWeight = '400';

    const container = document.getElementById('root');

    const html = `<div style="padding: 1em; position: absolute; top: 50%; left: 50%; margin-right: -50%; transform: translate(-50%, -50%); text-align: center;">
        <h1 style="font-weight: 300; line-height: 1.2; margin-bottom: 0.5rem;">
            This browser is no longer supported.
        </h1>
        <p>
            Your browser is not supported by this application. Please use one of the following browsers: Edge, Chrome, Firefox or Safari.
        </p>
    </div>`;

    if (container) {
        container.innerHTML = html;
    }
}
else {
    import("./mainApp").then(function (mainApp) {
        mainApp.loadApp();
    });
}